@tailwind base;
@tailwind components;
@tailwind utilities;

.disabled{
    opacity: 0.3;
}

.container{
    padding: 30px;
}

.position-alert-input{
    height: 35px;
}

.a-click{
    cursor: pointer;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
